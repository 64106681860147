"use client";
import { useUserData } from "../../hooks/useUserData";
import { membersNavLinks, navLinks } from "./links";
import { NavLink } from "./NavLink";

export function NavList({ t }) {
  const { data: user, isLoading } = useUserData();
  return (
    <>
      {!isLoading &&
        user.isLoggedIn &&
        membersNavLinks.map((link) => {
          return <NavLink key={link.slug} slug={link.slug} name={t[link.id]} />;
        })}
      {navLinks.map((link) => {
        return <NavLink key={link.slug} slug={link.slug} name={t[link.id]} />;
      })}
    </>
  );
}
