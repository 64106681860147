"use client";

import { useUserData } from "@/app/[locale]/hooks/useUserData";
import {
  ACCOUNT_LINK,
  CHECKOUT_LINK,
  FREE_TIER,
  HOME_LINK,
  PREMIUM_TIER,
} from "@/app/constants";
import { FreeSignupForm } from "../../shared/FreeSignupForm";
import { Link } from "@/navigation";
import { event as gaEvent } from "@/app/lib/gtag";

export function CTA({ text }) {
  const { data: user, isLoading } = useUserData();
  if (isLoading) return null;

  if (user.tier === FREE_TIER) {
    return (
      <>
        <h6 className="footer-title">{text.wantUnlimitedAccess}</h6>
        <div className="flex items-center">
          <Link
            href={CHECKOUT_LINK}
            className="btn btn-secondary btn-sm"
            onClick={() => {
              gaEvent("Checkout Upgrade CTA Clicked - Footer", {
                event_category: "Footer CTA",
                event_label: "",
                value: 0.5,
              });
            }}
          >
            {text.upgrade}
          </Link>
          <Link
            href={HOME_LINK}
            className="btn btn-sm ml-2"
            onClick={() => {
              gaEvent("Clicked learn more CTA", {
                event_category: "Footer CTA",
                event_label: "",
                value: 0.4,
              });
            }}
          >
            {text.learnMore}
          </Link>
        </div>
      </>
    );
  }

  if (user.tier === PREMIUM_TIER) {
    return (
      <>
        <h6 className="footer-title">{text.referralProgram}</h6>
        <Link
          href={`${ACCOUNT_LINK}#referral`}
          className="btn btn-outline btn-primary btn-sm bg-neutral"
          onClick={() => {
            gaEvent("Clicked Referral CTA", {
              event_category: "Footer CTA",
              event_label: "",
              value: 0.4,
            });
          }}
        >
          {text.shareWithAFriend}
        </Link>
      </>
    );
  }

  // Visitor is not logged in
  return (
    <>
      <h6 className="footer-title">{text.freeMembership}</h6>
      <FreeSignupForm withRedirect={false} />
    </>
  );
}
