"use client";

import { useRef, useTransition } from "react";
import { i18n } from "@/i18n";
import { Link, usePathname } from "@/navigation";
import { useLocale } from "next-intl";
import { useUserData } from "@/app/[locale]/hooks/useUserData";
import { updateLanguagePreference } from "@/app/actions/updateLanguagePref";
import clsx from "clsx";
import { useSearchParams } from "next/navigation";

export function LocaleSwitcher() {
  const { data: user, isLoading } = useUserData();
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isPending, startTransition] = useTransition();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleLanguageChange = (cur: string) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      if (!user?.isLoggedIn && !isLoading) return;

      startTransition(async () => {
        await updateLanguagePreference(cur);
      });
    }, 500);
  };

  const fullPath = searchParams
    ? `${pathname}?${searchParams.toString()}`
    : pathname;

  return (
    <div className="dropdown dropdown-end dropdown-top">
      <div tabIndex={0} role="button" className="btn m-1 bg-neutral">
        {locale.toUpperCase()}
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] w-24 bg-neutral p-2 text-base-300"
      >
        {i18n.locales.map((cur) => (
          <li key={cur}>
            <Link
              href={fullPath}
              className={clsx("hover:bg-base-200", {
                "pointer-events-none": isPending,
              })}
              locale={cur}
              lang={cur}
              onClick={() => handleLanguageChange(cur)}
            >
              {cur.toUpperCase()}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
